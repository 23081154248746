<template>
  <card>
    <collapse :multiple-active="false" :active-index="0">
      <!-- Final playlist description -->
      <collapse-item :title="$t('complexPl.collapseItem')" name="1">
        <div>
          <p align="center">{{ $t('complexPl.finishText0') }}</p>
          <div class="col-md-6 ml-auto mr-auto">
          <ValidationObserver v-slot="{ valid }">
            <ValidationProvider
              :rules="{ required: true, min: 3, max: 50}"
              v-slot="{ valid }"
              :bails="false" 
            > 
              <base-input>
                <el-input
                  type="text"
                  prefix-icon="el-icon-edit"
                  :placeholder="$t('notifications.smartError4')"
                  v-model="playlistName"
                >
                </el-input>
              </base-input>
              <span
                  v-if="!valid"
                  style="color: red; font-size: 12px;" 
              >
                {{ $t('validation.name') }}
              </span>
            </ValidationProvider>
          </ValidationObserver>
          </div>
          {{ $t('complexPl.finishText') }} {{  secToMin (this.$store.getters.adsTime) }}
          {{ $t('complexPl.finishText2') }} {{ secToMin (this.$store.getters.melTime) }}
          {{ $t('complexPl.finishText3') }} {{ this.$store.getters.startDay }}:00
          {{ $t('complexPl.finishText4') }} {{ this.actualEndDay }}
          {{ $t('complexPl.finishText6') }} {{ this.$store.getters.endDay }}:00
          {{ $t('complexPl.finishText5') }}
        </div>
      </collapse-item>

      <!-- Final playlist pivot table (actual replays count) -->
      <collapse-item :title="$t('complexPl.collapseItem3')" name="3">
        <div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination2.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination2.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery2"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <el-table :data="queriedData2" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.quantity') }}</b>: {{props.row.unic}} </p>

                  <audio controls class="audio" controlslist="nodownload" v-if="isAudio">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>

                  <video controls class="video" controlslist="nodownload" v-if="!isAudio">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                  
                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns2.slice(0, 1) : tableColumns2"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <!--Audio -->
              <el-table-column :min-width="30" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>

              <!--Video -->
              <el-table-column :min-width="30" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                <div slot-scope="props">
                  <video controls class="video" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from2 + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to2) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total2) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination2.currentPage"
              :per-page="pagination2.perPage"
              :total="total2"
            >
            </base-pagination>
          </div>
        </div>
      </collapse-item>

      <!-- Final playlist -->
      <collapse-item :title="$t('complexPl.collapseItem2')" name="2">
        <div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <!-- <p><b>{{ $t('dataTable.№') }}</b>: {{props.row.order}} </p> -->
                  <p><b>{{ $t('dataTable.time') }}</b>: {{props.row.time}} </p>
                  <p><b>{{ $t('dataTable.duration') }}</b>: {{props.row.duration}} </p>

                  <audio controls class="audio" controlslist="nodownload" v-if="isAudio">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>

                  <video controls class="video" controlslist="nodownload" v-if="!isAudio">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                  
                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 2) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <!--Audio -->
              <el-table-column :min-width="30" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>

              <!--Video -->
              <el-table-column :min-width="30" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                <div slot-scope="props">
                  <video controls class="video" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ Intl.NumberFormat(this.$i18n.locale).format(from + 1) }}
                {{ $t('pagination.2') }} {{ Intl.NumberFormat(this.$i18n.locale).format(to) }}
                {{ $t('pagination.3') }} {{ Intl.NumberFormat(this.$i18n.locale).format(total) }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>
      </collapse-item>
    </collapse>
  </card>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import Fuse from 'fuse.js';
import {Collapse, CollapseItem, BaseButton, BasePagination} from 'src/components';
import { search } from "@/util/functions.js";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";

extend('required', required);
extend('min', min);
extend('max', max);

export default {
  components: { 
    BaseButton,
    Collapse,
    CollapseItem,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },

    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    //Check for Smart playlist type change on first step
    isAudio() {
      return this.$store.getters.plType === 'AUDIO-SMART' ? true : false
    },

    //Summ duration in table
    avaliableDuration() {
      return Math.round(this.$store.getters.workTime / (this.$store.getters.melTime + this.$store.getters.adsTime) * this.$store.getters.adsTime)
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    }
  },
  data() {
    return {
      playlistName: 'Smart playlist ' + new Date().toLocaleString(),
      actualEndDay: '',
      loading: true,
      loading2: true,
      tableData: [],
      tableData2: [],
      searchedData: [],
      searchedData2: [],
      searchQuery: '',
      searchQuery2: '',
      fuseSearch: null,
      fuseSearch2: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
       pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'order',
          label: 'dataTable.№',
          minWidth: 20
        },
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'time',
          label: 'dataTable.time',
          minWidth: 20
        },
        {
          prop: 'duration',
          label: 'dataTable.duration',
          minWidth: 20
        }
      ],
      tableColumns2: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'unic',
          label: 'dataTable.quantity',
          minWidth: 20
        }
      ]
    };
  },
  methods: {
    //Seconds to minutes
    secToMin (sec) {
      let timeMark = new Date('December 25, 2021 00:00:00')
      timeMark.setSeconds( timeMark.getSeconds() + sec )
      return timeMark.toTimeString().split(' ')[0]
    },

    //Making playlist
    async makePlaylist () {

      // Variables section
      let adsList = this.$store.getters.adsList;
      let melList = this.$store.getters.melList;
      let blocks = Math.round(this.$store.getters.workTime / (this.$store.getters.melTime + this.$store.getters.adsTime));
      let playlistMel = [];
      let playlistAds = [];
      let playlistFinish = [];
      let technicalMssages = [];

      // Set date variable for counting start and end blocks time
      let blocksTime = new Date('December 25, 2021 ' + this.$store.getters.startDay)
      let blocksTimeArray = [];
      

      //------------------

      this.$notify({
        type: 'info',
        message: this.$t('notifications.smartPltWait'),
        icon: 'tim-icons icon-bell-55'
      });

      function shuffle(array) {
        // We create a copy of the array so as not to change the original array
        // const shuffledArr = [...arr];
        
        // Shuffling array elements using the Fisher-Yates method
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        
        // Checking that the same elements do not stand side by side
        for (let i = 0; i < array.length - 1; i++) {
          if (array[i].uid === array[i+1].uid) {
            // If two adjacent elements are equal, swap them with a random element
            let randomIndex = Math.floor(Math.random() * array.length);
            while (array[randomIndex].uid === array[i].uid || array[randomIndex].uid === array[i+1].uid) {
              randomIndex = Math.floor(Math.random() * array.length);
            }
            [array[i+1], array[randomIndex]] = [array[randomIndex], array[i+1]];
          }
        }
        
        return array;
      }


      // Function: Sorting by blocks
      function sortByBlocks(arrayIn, arrayOut, blockCount) {
        let blockNumber = 0;
        for (let i = 0; i < arrayIn.length; ++i) {
          let adsRestrict1 = (!arrayIn[i].interval) ? "" : new Date('December 25, 2021 ' + arrayIn[i].interval[0]);
          let adsRestrict2 = (!arrayIn[i].interval) ? "" : new Date('December 25, 2021 ' + arrayIn[i].interval[1]);
          // Filtering content without restriction
          if (!arrayIn[i].interval) {
            for (let i2 = 0; i2 < arrayIn[i].quantity; ++i2) {
              let Data = {
                'uid': arrayIn[i].uid,
                'name': arrayIn[i].name,
                'duration': arrayIn[i].duration,
                'url': arrayIn[i].url,
                'time': '',
                'order': ''
              }

              arrayOut[blockNumber].push(Data);
              
              blockNumber++
              if (blockNumber > blockCount-1) {
                blockNumber = 0
              }
            };
          } 
          // Filtering content with restriction but not technical messages
          else if ((adsRestrict2-adsRestrict1)/60000 > 15) {
            let blockNumberRestrict =0;

            for (let i2 = 0; i2 < arrayIn[i].quantity; ++i2) {
              let Data = {
                'uid': arrayIn[i].uid,
                'name': arrayIn[i].name,
                'duration': arrayIn[i].duration,
                'url': arrayIn[i].url,
                'time': '',
                'order': ''
              }

              // Check if block start/end time acceptible for Content restrictions
              if ((adsRestrict1 <= new Date(blocksTimeArray[blockNumberRestrict][0])) && (adsRestrict2 >= new Date(blocksTimeArray[blockNumberRestrict][1]))) {
                arrayOut[blockNumberRestrict].push(Data);
              }
              
              blockNumberRestrict++
              if (blockNumberRestrict > blockCount-1) {
                blockNumberRestrict = 0
              }
            };
          } 
          // Filtering technical messages with short and strictly defined playback times and save them separate
          else {
            let Data = {
              'uid': arrayIn[i].uid,
              'name': arrayIn[i].name,
              'duration': arrayIn[i].duration,
              'url': arrayIn[i].url,
              'quantity': arrayIn[i].quantity,
              'interval': arrayIn[i].interval
            }
            technicalMssages.push(Data);
          }
          
        }

        arrayOut.forEach(function(item, i, arr) {
          shuffle(arrayOut[i]);
        });

        return arrayOut;
      };

      // Function: Add the playback start time to the final playlist
      function addPlaybackTime(arrayIn) {

        // Set date variable for counting start/end playing time
        let timeMark = new Date('December 25, 2021 ' + this.$store.getters.startDay)
        let timeMarkEnd = new Date('December 25, 2021 ' + this.$store.getters.endDay)

        arrayIn.forEach(function(item, i, arr) {
          if (i == 0 ) {
            arrayIn[i].time = timeMark.toTimeString().split(' ')[0]
            arrayIn[i].order = i+1
          } else {
            timeMark.setSeconds( timeMark.getSeconds() + arrayIn[(i-1)].duration + 2 )
            // Delete all items after i, if end time go to the new day
            if (timeMark.getDate() > 25) {
              timeMark.setSeconds( timeMark.getSeconds() - 2 )
              this.actualEndDay = timeMark.toTimeString().split(' ')[0]
              arrayIn.splice(i)
              return
            }
            // Delete all items after i, if end time greater then EndDay
            if (timeMark > timeMarkEnd) {
              timeMark.setSeconds( timeMark.getSeconds() - 2 )
              this.actualEndDay = timeMark.toTimeString().split(' ')[0]
              arrayIn.splice(i)
              return
            }
            // Set actual end of the day if dont make splice array previously
            if (i == (arrayIn.length-1)) {
              timeMark.setSeconds( timeMark.getSeconds() + arrayIn[(i)].duration )
              this.actualEndDay = timeMark.toTimeString().split(' ')[0]
            }

            arrayIn[i].time = timeMark.toTimeString().split(' ')[0]
            arrayIn[i].order = i+1
          }
        }, this);

        return arrayIn;
      }

      // Step №1: Сreate the necessary number of subarrays in each content type.
      //          Сalculate the start and end time of each ad block (needed for ads with time limits)
      for( let i=0; i<blocks; i++ ) {
        playlistMel.push( [] );
        playlistAds.push( [] );
        blocksTimeArray.push( [] );

        blocksTimeArray[i].push(blocksTime.toString());
        blocksTime.setSeconds(blocksTime.getSeconds() + this.$store.getters.adsTime);
        blocksTimeArray[i].push(blocksTime.toString());
        blocksTime.setSeconds(blocksTime.getSeconds() + this.$store.getters.melTime);
      }

      // Step №2: Distribute content sequentially across blocks (in a chain). Separate ads and ringtones
      sortByBlocks(adsList, playlistAds, blocks)
      sortByBlocks(melList, playlistMel, blocks)

      // Step №3: Merge two playlists in one
      playlistAds.forEach(function(item, i, arr) {
        playlistMel[i].forEach(function(item2, i2, arr2) {
          playlistAds[i].push(playlistMel[i][i2]);
        });
      });

      // Step №4: Making an array one-dimensional
      playlistAds = playlistAds.flat();

      // Step №5: Add the playback start time to the final playlist
      addPlaybackTime.call(this, playlistAds);

      // Step №6: Insert technical messages to final playlist
      technicalMssages.forEach(function(item, i, arr) {
        let techTimer1 = new Date('December 25, 2021 ' + technicalMssages[i].interval[0])
        let techTimer2 = new Date('December 25, 2021 ' + technicalMssages[i].interval[1])
          
        for (let i3 = 0; i3 < technicalMssages[i].quantity; ++i3) { 
          playlistAds.every(function(item, i2, arr) {
            let playlistTimer = new Date('December 25, 2021 ' + playlistAds[i2].time)
            
            if ((techTimer1 < playlistTimer) && (techTimer2 > playlistTimer) && (i2 == 0 ? true: (playlistAds[i2-1].time !== ''))) {
              let Data = {
                'uid': technicalMssages[i].uid,
                'name': technicalMssages[i].name,
                'duration': technicalMssages[i].duration,
                'url': technicalMssages[i].url,
                'time': '',
                'order': ''
              }
              playlistAds.splice(i2, 0, Data);
              return false
            }
            return true
          }, this);
        }
      }, this);
      
      // Step №7: Re-add the playback start time to the final playlist
      addPlaybackTime.call(this, playlistAds);

      // Step №8: Save final playlist to the store and display it in data table
      playlistFinish = {
        'uid': this.$store.getters.smartPlUid === '' ? '' : this.$store.getters.smartPlUid,
        'name': this.playlistName,
        'description': this.$i18n.t('complexPl.workPickNam1') + ': "' + this.$store.getters.startDay + '" , ' + this.$i18n.t('complexPl.workPickNam2') + ': "' + this.actualEndDay + '". ' + this.$i18n.t('complexPl.blockPickNam1') + ': "' + this.secToMin(this.$store.getters.melTime) + '" , ' + this.$i18n.t('complexPl.blockPickNam2') + ': "' + this.secToMin(this.$store.getters.adsTime) + '".',
        'type': this.$store.getters.plType,
        'items': playlistAds,
        'source': {
          'AdsList': this.$store.getters.adsList,
          'MelList': this.$store.getters.melList,
          'MelTime': this.$store.getters.melTime,
          'AdsTime': this.$store.getters.adsTime,
          'StartDay': this.$store.getters.startDay,
          'EndDay': this.$store.getters.endDay
        }
      };

      await this.$store.commit('setFinishList', playlistFinish);
      await this.$store.commit('setPlFilter', playlistFinish.name);
      
      this.tableData = this.$store.getters.finishList.items;

      // Step №9: Find unique  elements in playlist
      this.tableData2 = playlistAds.filter((v,i,a)=>a.findIndex(t=>(t.uid === v.uid))===i)

      // Step №10: Count quantity of unique replays
      this.tableData2.forEach(function(item, i, arr) {
        this.tableData2[i].unic = 0
        playlistAds.forEach(function(item2, i2, arr2) {
          if (this.tableData2[i].uid == playlistAds[i2].uid) {
            this.tableData2[i].unic++
          }
        },this);
      },this);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.crtConfirmeSmrtPl'),
        icon: 'tim-icons icon-bell-55'
      });
    },

    // Fetch playlist
    fetchFinishPlaylist() {
      this.makePlaylist();
      this.loading = false;
      this.loading2 = false;

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      // First Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData2, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });
    },

    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        return res;
      });
    }
  },
  async mounted() {
    // Check if it`s Playlist edit (smartPlName fill by Name) then dont create new Name to Playlist
    if ( this.$store.getters.smartPlName.toString() !== '' ) {
      this.playlistName = this.$store.getters.smartPlName;
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
     playlistName(value) {
      let formData = this.$store.getters.finishList;
      formData.name = value;
      this.$store.commit('setFinishList', formData);
      this.$store.commit('setPlFilter', formData.name);
    },

    //Clear data in final tables if Playlist type on first page was changed
    isAudio() {
      this.tableData = [];
      this.searchedData = [];
      this.tableData2 = [];
      this.searchedData2 = [];
    },

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name');
    }
  }
};
</script>
<style></style>
