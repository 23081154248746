<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      
      <!-- Playlist type select -->
      <div class="col-lg-12 col-md-12 col-sm-12" >
        <card>
          <h4 class="card-title" align="center">
            {{ $t('complexPl.playlistType') }} 
          </h4>
          <div style="text-align: justify; text-indent: 1.5em">
            {{ $t('complexPl.typeDesc') }}
          </div>    
          <div style="text-align: justify; text-indent: 1.5em">
            {{ $t('complexPl.typeDesc2') }} 
          </div>

          <br>

          <div class="col-md-6 ml-auto mr-auto">
            <div class="row time-intervals">
              <span class="card-title label-switch"> {{ $t('complexPl.typePick2') }} </span>
              <base-switch v-model="plType">
                <i class="tim-icons icon-headphones" slot="on"></i>
                <i class="tim-icons icon-tv-2" slot="off"></i>
              </base-switch>
              <span class="card-title label-switch label-right"> {{ $t('complexPl.typePick1') }} </span>
            </div> 
          </div>
        </card>
      </div>
      <div class="row">
        <!-- Work time select -->
        <div class="col-lg-6 col-md-6 col-sm-6" >
          <card>
            <h4 class="card-title" align="center">
              {{ $t('complexPl.workTitle') }} 
            </h4>
            <div style="text-align: justify; text-indent: 1.5em">
              {{ $t('complexPl.workDesc') }}
            </div>     
            <div style="text-align: justify; text-indent: 1.5em">
              {{ $t('complexPl.workDesc2') }} 
            </div>

            <br>

            <div class="col-md-6 ml-auto mr-auto">
              <div class="row" style="justify-content: center; display: flex; justify-content: space-around;">
                <span class="card-title label-switch"> {{ $t('complexPl.workSwitch1') }} </span>
                <base-switch v-model="workHours">
                  <i class="tim-icons icon-refresh-02" slot="on"></i>
                  <i class="tim-icons icon-time-alarm" slot="off"></i>
                </base-switch>
                <span class="card-title label-switch label-right"> {{ $t('complexPl.workSwitch2') }} </span>
              </div> 
            </div>

            <div v-if="!workHours">
              <hr>
              <div class="row time-intervals">
                <h5> {{ $t('complexPl.workPickNam1') }} </h5>
                <h5> {{ $t('complexPl.workPickNam2') }} </h5>
              </div>

              <div class="row time-intervals">        
                <ValidationProvider
                  name="melBlock"
                  rules="required"
                  v-slot="{ passed, failed }"
                >   
                  <base-input
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      :placeholder="$t('complexPl.workPlaceHol')"
                      v-model="startTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59'
                      }">
                    </el-time-select>
                  </base-input>
                </ValidationProvider>
                
                <ValidationProvider
                    name="adsBlock"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >   
                  <base-input
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      :placeholder="$t('complexPl.workPlaceHol')"
                      v-model="endTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59',
                        minTime: startTime
                      }"
                    >
                    </el-time-select>
                  </base-input>
                </ValidationProvider>              
              </div>
            </div>
          </card>
        </div>

        <!-- Blocks select -->
        <div class="col-lg-6 col-md-6 col-sm-6" >
          <card>
            <h4 class="card-title" align="center">
              {{ $t('complexPl.blockTitle') }} 
            </h4>
            <div style="text-align: justify; text-indent: 1.5em">
              {{ $t('complexPl.blockDesc') }}
            </div>    
            <div style="text-align: justify; text-indent: 1.5em">
              {{ $t('complexPl.blockDesc2') }} 
            </div>

            <br>

            <div class="col-md-6 ml-auto mr-auto">
              <div class="row time-intervals">
                <span class="card-title label-switch"> {{ $t('complexPl.blockSwitch1') }} </span>
                <base-switch v-model="block">
                  <i class="tim-icons icon-laptop" slot="on"></i>
                  <i class="tim-icons icon-tap-02" slot="off"></i>
                </base-switch>
                <span class="card-title label-switch label-right"> {{ $t('complexPl.blockSwitch2') }} </span>
              </div> 
            </div>

      
            <div v-if="!block">
              <hr>
              <div class="row time-intervals">
                <h5> {{ $t('complexPl.blockPickNam1') }} </h5>
                <h5> {{ $t('complexPl.blockPickNam2') }} </h5>
              </div>

              <div class="row time-intervals">        
                <ValidationProvider
                  name="melBlock"
                  rules="required"
                  v-slot="{ passed, failed }"
                >   
                  <base-input
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      :placeholder="$t('complexPl.blockPlaceHol')"
                      v-model="melBlock"
                      :picker-options="{
                        start: '00:05',
                        step: '00:05',
                        end: '01:00'
                      }">
                    </el-time-select>
                  </base-input>
                </ValidationProvider>
                
                <ValidationProvider
                    name="adsBlock"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >   
                  <base-input
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-time-select
                      :placeholder="$t('complexPl.blockPlaceHol')"
                      v-model="adsBlock"
                      :picker-options="{
                        start: '00:05',
                        step: '00:05',
                        end: '01:00'
                      }">
                    </el-time-select>
                  </base-input>
                </ValidationProvider>              
              </div>
            </div>
          </card>
        </div>
      </div>
      
    </form>
  </ValidationObserver>
</template>
<script>
import { BaseSwitch } from 'src/components';
import { TimeSelect} from 'element-ui'
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

export default {
   components: {
    BaseSwitch,
    [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
        startTime: '',
        endTime: '',
        adsBlock: '',
        melBlock: '',
        plType: this.$store.getters.plType === 'AUDIO-SMART' ? true : false,
        workHours: true,
        block: true
    };
  },
  methods: {

    //Transform to seconds
    hoursToSeconds (str) {
      str = str.split(":");
      str = parseInt(str[0])*3600 + parseInt(str[1]*60)
      return str
    },

    //Transform to hours
    secondsToHours (str) {
      let hours = Math.floor(str/3600);
      let min = str/60 > 9 ? str/60 : '0' + str/60;
      str = '0' + hours + ':' + min

      return str
    },

    //Validation part of form for next spet pass
    validate() {
      //Set playlist type
      this.$store.commit('setPlType', this.plType ? 'AUDIO-SMART' : 'VIDEO-SMART');

      //Set ads and melodies period
      if (!this.block) {
        this.$store.commit('setMelTime', this.hoursToSeconds(this.melBlock));
        this.$store.commit('setAdsTime', this.hoursToSeconds(this.adsBlock));
      } else {
        this.$store.commit('setMelTime', 360);
        this.$store.commit('setAdsTime', 900);
      };

      //Set work time for day
      if (!this.workHours) {
        this.$store.commit('setWorkTime', this.hoursToSeconds(this.endTime)-this.hoursToSeconds(this.startTime));
        this.$store.commit('setStartDay', this.startTime);
        this.$store.commit('setEndDay', this.endTime);
      } else {
        this.$store.commit('setWorkTime', this.hoursToSeconds('23:59')-this.hoursToSeconds('00:00'));
        this.$store.commit('setStartDay', '00:00');
        this.$store.commit('setEndDay', '23:59');
      };

      
      if ((this.startTime !== '' || this.endTime !== '') && (this.startTime > this.endTime)) {
        this.$notify({
          type: 'danger',
          message: this.$t('notifications.smartError5'),
          icon: 'tim-icons icon-bell-55'
        });   
      } else {
        return this.$refs.form.validate().then(res => {
          this.$emit("on-validated", res);
          return res;
        });
      }
    }
  },
  mounted() {
    // Loading data from store if it`s Smart playlist editing
    if (this.$store.getters.startDay !== '00:00' || this.$store.getters.endDay !== '23:59') {
      this.workHours = false;
      this.startTime = this.$store.getters.startDay;
      this.endTime = this.$store.getters.endDay;
    }

    if (this.$store.getters.melTime !== 360 || this.$store.getters.adsTime !== 900) {
      this.block = false;
      this.adsBlock = this.secondsToHours(this.$store.getters.adsTime);
      this.melBlock = this.secondsToHours(this.$store.getters.melTime);
    }    
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query*/
     
    //Notify that changing of Playlist type make clearing all filled content blocks
    plType() {
      this.$notify({
        type: 'danger',
        message: this.$t('notifications.smartError3'),
        icon: 'tim-icons icon-bell-55'
      })
    }
  }
};
</script>
<style>
</style>
