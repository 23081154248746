<template>
  <card card-body-classes="table-full-width">
    <tabs :type="colorUnification">
      <tab-pane :label="$t('dataTable.melBlock')">
        <div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
              
            <div class="col d-flex justify-content-center">
              <span v-if="tableData.length > 0" :class="[(sumDuration < avaliableDuration) || (sumDuration > avaliableDuration*1.15) ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-success']">
              {{ $t('dataTable.sumQuantity') }} {{ Intl.NumberFormat(this.$i18n.locale).format(sumDuration) }}
              {{ $t('dataTable.sumQuantityOf') }}
              {{ Intl.NumberFormat(this.$i18n.locale).format(avaliableDuration) }}
              </span>
            </div>

            <el-table :data="queriedData" v-loading="loading" :empty-text="loading ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" width="25">
                <div slot-scope="props">
                  <div v-if="isMobile">
                    <p><b>{{ $t('dataTable.sum') }}</b>: {{props.row.sum}} </p>
                    <p><b>{{ $t('dataTable.quantity') }}</b>:
                      <el-input-number
                        v-model="props.row.quantity"
                        v-on:input="props.row.sum = props.row.quantity * props.row.duration" 
                        :min="1" 
                        :max="1000"
                        size="mini"
                      />
                    </p>
                  </div>

                  <audio controls class="audio" controlslist="nodownload" v-if="isAudio">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>

                  <video controls class="video" controlslist="nodownload" v-if="!isAudio">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                  
                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns.slice(0, 1) : tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"
              >
              </el-table-column>

              <!--Audio -->
              <!-- <el-table-column :min-width="30" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column> -->

              <!--Video -->
              <!-- <el-table-column :min-width="30" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                <div slot-scope="props">
                  <video controls class="video" controlslist="nodownload" :key="props.row.uid">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column> -->

              <el-table-column :min-width="20" align="right" :label="$t('dataTable.quantity')" v-if="!isMobile">
                <div slot-scope="props">
                  <el-input-number
                    v-model="props.row.quantity"
                    v-on:input="props.row.sum = props.row.quantity * props.row.duration" 
                    :min="1" 
                    :max="1000"
                    size="mini"
                  />
                </div>
              </el-table-column>
              
              <el-table-column :min-width="isMobile ? 25 : 10" align="right" :label="$t('dataTable.del')">
                <div slot-scope="props">
                  <base-button
                    @click.native="delFromPlaylist(props.$index, props.row.name)"
                    round
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ from + 1 }}
                {{ $t('pagination.2') }} {{ to }}
                {{ $t('pagination.3') }} {{ total }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>
      </tab-pane>

      <tab-pane :label="$t(isAudio ? 'dataTable.melLibrary' : 'dataTable.videoLibrary')">
        <div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                :class="['select-'+ colorUnification + ' mb-3 pagination-select']"
                :type="colorUnification"
                v-model="pagination2.perPage"
                placeholder="Per page"
              >
                <el-option
                  :class="['select-'+ colorUnification]"
                  v-for="item in pagination2.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('profileAction.search')"
                  v-model="searchQuery2"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData2" v-loading="loading2" :empty-text="loading2 ? $t('dataTable.loading') : $t('dataTable.noData')">
              <el-table-column type="expand" v-if="isMobile" width="25">
                <div slot-scope="props">
                  <p><b>{{ $t('dataTable.duration') }}</b>: {{props.row.duration}} </p>

                  <audio controls class="audio" controlslist="nodownload" v-if="isAudio">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>

                  <video controls class="video" controlslist="nodownload" v-if="!isAudio">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>

                </div>
              </el-table-column>
              
              <el-table-column
                v-for="column in isMobile ? tableColumns2.slice(0, 1) : tableColumns2"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
                :formatter="column.formatter"
                sortable
                :align="$rtl.isRTL ? 'right' : 'left'"                
              >
              </el-table-column>
              <!--Audio -->
              <el-table-column :min-width="30" :label="$t('dataTable.listen')" v-if="isAudio && !isMobile">
                <div slot-scope="props">
                  <audio controls class="audio" controlslist="nodownload">
                    <source :src="props.row.url" type="audio/mp3" >
                  </audio>
                </div>
              </el-table-column>

              <!--Video -->
              <el-table-column :min-width="30" :label="$t('dataTable.watch')" v-if="!isAudio && !isMobile">
                <div slot-scope="props">
                  <video controls class="video" controlslist="nodownload">
                    <source :src="props.row.url" type="video/mp4" >
                  </video>
                </div>
              </el-table-column>

              <el-table-column :min-width="isMobile ? 25 : 10" align="right" :label="$t('dataTable.add')">
                <div slot-scope="props">
                  <base-button
                    @click.native="addToPlaylist(props.row.uid, props.row.name, props.row.duration, props.row.url)"
                    round
                    :type="colorUnification"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.1') }} {{ from2 + 1 }}
                {{ $t('pagination.2') }} {{ to2 }}
                {{ $t('pagination.3') }} {{ total2 }}
                {{ $t('pagination.4') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :type="colorUnification"
              v-model="pagination2.currentPage"
              :per-page="pagination2.perPage"
              :total="total2"
            >
            </base-pagination>
          </div>
        </div>
      </tab-pane>     
    </tabs>
  </card> 
</template>
<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import { TabPane, Tabs, BasePagination, Slider, Modal} from 'src/components';
import Fuse from 'fuse.js';
import { search } from "@/util/functions.js";


export default {
  components: {
    TabPane,
    Tabs,
    Slider,
    Modal,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    // Device with small screen detection for reduce data in tables
    isMobile() {
      return window.matchMedia("only screen and (max-width: 480px)").matches;
    },
    
    // Unify the colors of elements across the interface
    colorUnification() {
      return this.$store.getters.colorUnification
    },

    //Check for Smart playlist type change on first step
    isAudio() {
      return this.$store.getters.plType === 'AUDIO-SMART' ? true : false
    },

    //Summ duration in table
    sumDuration() {
      return this.tableData.map(x => x['sum']).reduce((accumulator, currentValue) => accumulator + currentValue)
           + 2 * this.tableData.map(x => x['quantity']).reduce((accumulator, currentValue) => accumulator + currentValue)
    },

    //Summ duration in table
    avaliableDuration() {
      return Math.round(this.$store.getters.workTime / (this.$store.getters.melTime + this.$store.getters.adsTime) * this.$store.getters.melTime)
    },

    // Returns a page from the searched data or the whole data. Search is performed in the watch section below
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    }
  },
  data() {
    return {
      loading: true,
      loading2: true,
      loading3: true,
      tableData: [],
      tableData2: [],
      searchedData: [],
      searchedData2: [],
      searchQuery: '',
      searchQuery2: '',
      fuseSearch: null,
      fuseSearch2: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
       pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [25, 50, 100, 250],
        total: 0
      },
      tableColumns: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'sum',
          label: 'dataTable.sum',
          minWidth: 20,
          formatter: (row, col, cellValue, index) => {           
           return Intl.NumberFormat(this.$i18n.locale).format(cellValue)
          }
        }
      ],
      tableColumns2: [
        {
          prop: 'name',
          label: 'dataTable.name',
          minWidth: 40,
          formatter: (row, col, cellValue, index) => {
            return <router-link to={ (this.isAudio ? '/media/melodiesOne/' : '/media/videosOne/') + row.uid}> {cellValue} </router-link>
          }
        },
        {
          prop: 'duration',
          label: 'dataTable.duration',
          minWidth: 20
        }
      ]
    };
  },
  methods: {
    // Fetch Ads for adding in Playlist
    async fetchMedia() {
      this.loading2 = true;
      const Data = {
        'contentType': this.isAudio ? 'audio' : 'video'
      }
      this.tableData2 = await this.$store.dispatch('fetchMedia', Data);

      // Second Fuse search initialization.
      this.fuseSearch2 = new Fuse(this.tableData2, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name'],
        threshold: 0.1
      });

      this.loading2 = false;
    },

    // Fetch Smart playlist edit page
    async fetchSmartPl() {
      // Loading data from store if it`s Smart playlist editing
      if (this.$store.getters.melList.length !== 0) {
        this.tableData = this.$store.getters.melList;
      }

      // First Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        useExtendedSearch: true,
        ignoreLocation: true,
        keys: ['name', 'duration'],
        threshold: 0.1
      });

      this.loading = false;
    },

    // Delete Media from Playlist
    async delFromPlaylist(value) {
      this.tableData.splice(value, 1);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.outConfirmePl200'),
        icon: 'tim-icons icon-bell-55'
      });

      this.searchQuery = '';
      await this.fetchSmartPl();
    },

    // Add Media to Playlist
    async addToPlaylist(value, value2, value3, value4) {
      const Data = {
        'uid': value,
        'name': value2,
        'url': value4,
        'duration': value3,
        'quantity': 1,
        'sum': 1*value3,
        'interval': null
      };
      
      this.tableData.push(Data);

      this.$notify({
        type: 'success',
        message: this.$t('notifications.inConfirmePl200'),
        icon: 'tim-icons icon-bell-55'
      });

      this.searchQuery = '';
      await this.fetchSmartPl();
    },
    
    validate() {
      if (this.tableData.length > 0) {
        if ((this.sumDuration < this.avaliableDuration) || (this.sumDuration > this.avaliableDuration*1.15)) {
          this.$notify({
            type: 'danger',
            message: this.$t('notifications.smartError2'),
            icon: 'tim-icons icon-bell-55'
          });
        } else {
          //Set melodies list for play
          this.$store.commit('setMelList', this.tableData);
          this.$emit('on-validated', true, this.model);

          return Promise.resolve(true);
        }     
      } else {
        this.$notify({
          type: 'danger',
          message: this.$t('notifications.smartError'),
          icon: 'tim-icons icon-bell-55'
        });
      }
    }
  },
  async mounted() {

    // Smart playlist page
    await this.fetchSmartPl()
    
    // Fetch Ads for adding in Playlist (DATATABLE2)
    await this.fetchMedia()
    
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

    //Clear block if Playlist type on first page was changed
    async isAudio() {
      this.tableData2 = []
      this.searchedData2 = []

      // Fetch VideoAd for adding in Playlist (DATATABLE2)
      await this.fetchMedia()

      this.tableData = []
      this.searchedData = []
    },

    searchQuery(value) {
      this.searchedData = search(value, this.tableData, this.fuseSearch, 'name', 'duration');
    },
    searchQuery2(value) {
      this.searchedData2 = search(value, this.tableData2, this.fuseSearch2, 'name');
    }
  }
};
</script>
<style>
</style>
