<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <simple-wizard>
          <template slot="header">
            <h3 class="card-title">{{ $t('complexPl.title') }}</h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-time-alarm"></i>
              <p>{{ $t('complexPl.step1') }}</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-coins"></i>
              <p>{{ $t('complexPl.step2') }}</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-volume-98"></i>
              <p>{{ $t('complexPl.step3') }}</p>
            </template>
            <third-step 
              ref="step3"
              @on-validated="makePlaylist(), onStepValidated"
            ></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-check-2"></i>
              <p>{{ $t('complexPl.step4') }}</p>
            </template>
            <fourth-step 
              ref="step4"
              @on-validated="onStepValidated"
            ></fourth-step>
          </wizard-tab>

        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './Wizard/FirstStep.vue';
import SecondStep from './Wizard/SecondStep.vue';
import ThirdStep from './Wizard/ThirdStep.vue';
import FourthStep from './Wizard/FourthStep.vue';
import { SimpleWizard, WizardTab } from 'src/components';

export default {
  data() {
    return {
      wizardModel: {}
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    SimpleWizard,
    WizardTab
  },
  methods: {
    makePlaylist () {
      this.$refs['step4'].fetchFinishPlaylist();
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      
    }
  }
};
</script>
